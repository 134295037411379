import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
// import ChartCard from '../components/HomeCards/ChartCard'
// import StatsCard from '../components/HomeCards/StatsCard'
import StreamOfflineCard from '../components/HomeCards/StreamOfflineCard'
import PageSidebarContainer from '../components/PageSidebarContainer'
import { setPageHeader } from '../store/actions/graphic'
import { Reducers, ReducerUser } from '../types/reducers'
import { Grants } from '../utility/Grants'

const HomePage: React.FC = () => {
    const { t } = useTranslation()
    const dispatch = useDispatch()

    const user = useSelector<Reducers, ReducerUser>((state) => state.user)

    console.log(user)

    useEffect(() => {
        dispatch(
            setPageHeader({
                parent: null,
                name: t('headers.homepage'),
            })
        )
    }, [])

    console.log('refreshToken:', user.user?.refreshToken)

    return (
        <PageSidebarContainer>
            <div className="u-row-page" style={{ marginBottom: 50 }}>
                {user.user?.grants?.includes(Grants.MANAGE_SYSTEM) ? (
                    <StreamOfflineCard />
                ) : (
                    <div>
                        <h1>{t('homepage.welcome')}</h1>
                        <h4>{t('homepage.welcome_subtitle')}</h4>
                    </div>
                )}

                {/* <StatsCard /> */}
            </div>
            {/* <ChartCard /> */}
        </PageSidebarContainer>
    )
}

export default HomePage
