import React, { useEffect } from 'react'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import RootComponent from './RootComponent'
import { persistor, store } from './store/reducers'
import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import italian from './locales/general/it.json'
import english from './locales/general/en.json'
import french from './locales/general/fr.json'

const translations = {
    it: {
        translation: italian,
    },
    en: {
        translation: english,
    },
    fr: {
        translation: french,
    },
}
const FALLBACK_LANGUAGE = 'en'

const App: React.FC = () => {
    useEffect(() => {
        const browserLanguage = navigator.language.split('-')[0] // e.g. from navigator output 'it-IT' returns 'it'
        console.log(browserLanguage)
        void i18n
            .use(initReactI18next) // passes i18n down to react-i18next
            .init({
                resources: translations,
                lng: browserLanguage || FALLBACK_LANGUAGE,
                fallbackLng: FALLBACK_LANGUAGE,
                interpolation: {
                    escapeValue: false,
                },
            })
    }, [])

    return (
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <RootComponent />
            </PersistGate>
        </Provider>
    )
}

export default App
