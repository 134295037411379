import { RadioItem } from '../components/AppPreview/AppGrid/AppGrid'
import { Frequency, Position } from '../types/data'
import { Languages } from './Languages'
import { table_locale_itIT } from '../locales/tables/table_locale_itIT'
import { table_locale_enEN } from '../locales/tables/table_locale_enEN'
import { cron_locale_itIT } from '../locales/cron/cron_locale_itIT'
import { cron_locale_enEN } from '../locales/cron/cron_locale_enEN'

/**
 * This function can be used anywhere in the app to greet the user
 * @param userName The user's first name
 * @returns A kind greeting message
 */
export const sayHello = (userName: string): string => {
    return `Welcome ${userName}!`
}

export const toCamelCaseKey = (key: string): string => key.replace(/(_\w)|(-\w)/g, (k: string) => k[1].toUpperCase())

export const toCamelCase = (object: any): any => {
    let transformedObject = object
    if (typeof object === 'object' && object !== null) {
        if (object instanceof Array) {
            transformedObject = object.map(toCamelCase)
        } else {
            transformedObject = {}
            Object.keys(object).map((key: any) => {
                if (object[key] !== undefined) {
                    const newKey = toCamelCaseKey(key)
                    transformedObject[newKey] = toCamelCase(object[key])
                }
                return
            })
        }
    }
    return transformedObject
}

export const getRequestHeader = (token: string, type?: string) => {
    return { headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': type || 'application/json' } }
}

export const toSnackCaseKey = (key: string): string =>
    key
        .replace(/\.?([A-Z]+)/g, (_, y: string) => {
            return `_${y.toLowerCase()}`
        })
        .replace(/^_/, '')

export const isCamelCase = (key: string): boolean => /\.?([A-Z]+)/g.test(key)

export const toSnackCase = (object: any): any => {
    let transformedObject = object
    if (typeof object === 'object' && object !== null) {
        if (object instanceof Array) {
            transformedObject = object.map(toSnackCase)
        } else {
            transformedObject = {}
            Object.keys(object).map((key: string) => {
                if (object[key] !== undefined) {
                    const newKey = toSnackCaseKey(key)
                    transformedObject[newKey] = toSnackCase(object[key])
                }
                return
            })
        }
    }
    return transformedObject
}

/**
 * Detects inside or outside clicks on a specific HTML element
 * @param e mouse click
 * @param panelElement element to test
 * @returns flag that indicates the click event happened inside the element
 */
export const detectOutsideClick = (e: MouseEvent, panelElement: React.MutableRefObject<null>): boolean => {
    return !!(panelElement.current && (panelElement.current as HTMLElement).contains(e.target as HTMLElement))
}

export const sortArrayPerPositions = (array: Position[]) => {
    return array.sort((a, b) => {
        if (a.bundleInfo.position < b.bundleInfo.position) {
            return -1
        }
        if (a.bundleInfo.position > b.bundleInfo.position) {
            return 1
        }
        return 0
    })
}

export const sortArrayPerFrequency = (array: Frequency[]) => {
    return array.sort((a, b) => {
        if (a.frequency < b.frequency) {
            return -1
        }
        if (a.frequency > b.frequency) {
            return 1
        }
        return 0
    })
}

export const converterToPagerItem = (radios: Position[], isFavoritePage?: boolean) => {
    const data: RadioItem[] = []

    const image = (r: Position): string => {
        const weight = r.bundleInfo && r.bundleInfo.weight && !isFavoritePage ? r.bundleInfo.weight : 1
        switch (weight) {
            case 1:
                return r.logo as string
            case 2:
                return r.imageH2 ? (r.imageH2 as string) : (r.logo as string)
            case 3:
                return r.imageH3 ? (r.imageH3 as string) : (r.logo as string)
            case 4:
                return r.imageH4 ? (r.imageH4 as string) : (r.logo as string)
            default:
                return r.logo as string
        }
    }

    radios.forEach((r) => {
        data.push({
            id: r.id,
            title: r.name,
            weight: r.bundleInfo && r.bundleInfo.weight && !isFavoritePage ? r.bundleInfo.weight : 1,
            sponsored: r.bundleInfo && r.bundleInfo.sponsored ? r.bundleInfo.sponsored : false,
            image: { url: image(r) },
        })
    })

    return data
}

export const passwordRegEx =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[.!@#$%^&*_\-+=|?])[a-zA-Z0-9.!@#$%^&*_\-+=|?]{8,}$/

export const emailRegEx =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export const phoneRegEx = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/

export const getLangTable = (lang: Languages) => {
    switch (lang) {
        case Languages.italian:
            return table_locale_itIT

        case Languages.english:
            return table_locale_enEN

        default:
            return table_locale_enEN
    }
}

export const getCronLocale = (lang: Languages) => {
    switch (lang) {
        case Languages.italian:
            return cron_locale_itIT

        case Languages.english:
            return cron_locale_enEN

        default:
            return cron_locale_enEN
    }
}

export const deepClone = <T>(input: T): T => {
    return JSON.parse(JSON.stringify(input))
}

export function isValidUrl(url: string): boolean {
    try {
        new URL(url)
        return true
    } catch (e) {
        return false
    }
}
