import React, { useEffect, useMemo, useRef, useState } from 'react'
import Button from '../../../components/Button/Button'
import { getNetworks, getRadioDetail, getRadios } from '../../../resources/api-constants'
import { COLORS } from '../../../resources/colors'
import { Category, Network, Radio, Tag } from '../../../types/data'
import FmWorldAxios from '../../../utility/FmWorldAxios'
import '../../../components/Grids/Grid.sass'
import Loader from '../../../components/Loader/Loader'
import TextInput from '../../../components/TextInput/TextInput'
import SelectInput, { Option } from '../../../components/SelectInput/SelectInput'
import { useSelector } from 'react-redux'
import { Reducers, ReducerData, ReducerUser } from '../../../types/reducers'
import UploadInput from '../../../components/UploadInput/UploadInput'
import { VoidRadio, voidRadio } from '../../../utility/voidConstants'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from '../../../resources/routes-constants'
import DeleteModal from '../../../components/Modals/DeleteModal'
import LoadingModal from '../../../components/Modals/LoadingModal'
import FmWorldMultipartAxios from '../../../utility/FmWorldMultipartAxios'
import { useDispatch } from 'react-redux'
import { fetchRadios } from '../../../store/actions/thunk_actions'
import { Grants } from '../../../utility/Grants'
import CheckboxInput from '../../../components/CheckboxInput/CheckboxInput'
import { PLACEHOLDER } from '../../../resources/constants'
import { useTranslation } from 'react-i18next'
import { emailRegEx, phoneRegEx } from '../../../utility/functions'

interface Props {
    idRadio?: string
    idNetwork: number | null
    permissions: Grants[]
    nameRadio?: string
}

const RadioDetailTab: React.FC<Props> = (props) => {
    const [radioDetail, setRadioDetail] = useState<Radio | VoidRadio | null>(voidRadio)
    const [isLoading, setIsLoading] = useState(false)
    const [isUpdating, setIsUpdating] = useState(false)
    const [isCreating, setIsCreating] = useState(false)
    const [showModalDelete, setShowModalDelete] = useState(false)
    const [error, setError] = useState('')
    const [networks, setNetworks] = useState<Option[]>([])
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const data = useSelector<Reducers, ReducerData>((state) => state.data)
    const loggedUser = useSelector<Reducers, ReducerUser>((state) => state.user)
    const logoRef = useRef<HTMLImageElement>(null)
    const imageH2Ref = useRef<HTMLImageElement>(null)
    const imageH3Ref = useRef<HTMLImageElement>(null)
    const imageH4Ref = useRef<HTMLImageElement>(null)
    const { t } = useTranslation()

    const fetchRadioDetail = async () => {
        setIsLoading(true)
        try {
            const radio = await FmWorldAxios.get(getRadioDetail(parseInt(props.idRadio ?? '', 10))).then((res) => {
                return res.data
            })
            setRadioDetail(radio)
        } catch (error: any) {
            setError(error.toString())
        }
        setIsLoading(false)
    }

    const dataIsInvalid = useMemo(() => {
        if (radioDetail) {
            return (
                !radioDetail.name ||
                !radioDetail.email ||
                !radioDetail.idCountry ||
                (radioDetail.phone && !phoneRegEx.test(radioDetail.phone)) === true ||
                (radioDetail.email && !emailRegEx.test(radioDetail.email)) === true
            )
        }
        return true
    }, [radioDetail])

    const fetchNetworksList = async () => {
        setError('')
        setIsLoading(true)
        try {
            const networksFetch = await FmWorldAxios.get(getNetworks()).then((res) => {
                return res.data.items
            })
            const networksOptions = networksFetch.map((n: Network) => {
                return {
                    id: n.id,
                    value: n.id,
                    label: n.name,
                }
            })

            setNetworks([...networksOptions, { id: null, value: null, label: 'No Network' }])
        } catch (error: any) {
            console.log('ERROR', error)
            setError(error.toString())
        }
        setIsLoading(false)
    }

    const networkValue = () => {
        if (radioDetail && radioDetail.idNetwork) {
            const networkFound = networks.find((n) => n.id === radioDetail.idNetwork)
            if (networkFound) {
                return networkFound
            }
        }
        return null
    }

    useEffect(() => {
        if (props.idRadio && props.idRadio !== 'new') {
            void fetchRadioDetail()
        } else {
            if (props.idNetwork !== undefined) {
                setRadioDetail({ ...voidRadio, idNetwork: props.idNetwork, name: props.nameRadio || '' })
            } else {
                setRadioDetail({ ...voidRadio, idNetwork: data.singleNetwork ? data.singleNetwork.id : null })
            }
        }
        if (!data.singleNetwork) {
            void fetchNetworksList()
        }
    }, [props.idRadio, props.idNetwork, data.singleNetwork, props.nameRadio])

    const handleInsertLogo = (e: any, ref: React.RefObject<HTMLImageElement>, onChange: (img: File) => void) => {
        if (e.target.files[0] && radioDetail) {
            const image = e.target.files[0]
            if (image) {
                onChange(image)
                const fr = new FileReader()
                fr.onload = function () {
                    if (ref && ref.current && fr.result) {
                        ref.current.src = fr.result as string
                    }
                }
                fr.readAsDataURL(image)
            }
        }
    }

    const currentRadioType = useMemo(() => {
        if (radioDetail && radioDetail.idRadioType) {
            const found = data.radioTypes.find((r) => r.id === radioDetail.idRadioType)
            if (found) {
                return {
                    id: found.id,
                    value: found.id,
                    label: found.value,
                }
            }
            return null
        }
        return null
    }, [radioDetail?.idRadioType, data.radioTypes])

    const currentCountry = useMemo(() => {
        if (radioDetail && radioDetail.idCountry) {
            const found = data.countries.find((r) => r.id === radioDetail.idCountry)
            if (found) {
                return {
                    id: found.id,
                    value: found.id,
                    label: found.name,
                }
            }
            return null
        }
        return null
    }, [radioDetail?.idCountry, data.countries])

    const currentCategories = useMemo(() => {
        if (radioDetail && radioDetail.categories) {
            return radioDetail.categories.map((c) => {
                return {
                    id: c.id,
                    value: c.id,
                    label: c.value,
                }
            })
        }
        return []
    }, [radioDetail?.categories])

    const currentTags = useMemo(() => {
        if (radioDetail && radioDetail.tags) {
            return radioDetail.tags.map((c) => {
                return {
                    id: c.id,
                    value: c.id,
                    label: c.value,
                }
            })
        }
        return []
    }, [radioDetail?.tags])

    const editRadio = async () => {
        if (radioDetail) {
            setError('')
            setIsUpdating(true)
            try {
                const updatedRadio = {
                    ...radioDetail,
                    categories: radioDetail?.categories.map((c) => {
                        return c.id
                    }),
                    tags: radioDetail?.tags?.map((t) => {
                        return t.id
                    }),
                }
                const formData = new FormData()
                Object.keys(updatedRadio).map((key) => {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    const currentField = updatedRadio[key as any]
                    formData.append(key, Array.isArray(currentField) ? JSON.stringify(currentField) : currentField)
                    return
                })
                await FmWorldMultipartAxios.put(getRadioDetail(parseInt(props.idRadio ?? '', 10)), formData)
                setIsUpdating(false)
                navigate(`${ROUTES.RADIO_DETAIL_ROUTE}${props.idRadio}`, {
                    state: {
                        name: updatedRadio.name,
                        idNetwork: data.singleNetwork ? data.singleNetwork.id : radioDetail.idNetwork,
                    },
                })
            } catch (error: any) {
                console.log('ERROR', error)
                setIsUpdating(false)
                setError(error.toString())
            }
        }
    }

    const createRadio = async () => {
        console.log('radioDetail', radioDetail)
        if (radioDetail && radioDetail.name) {
            setError('')
            setIsCreating(true)
            try {
                const updatedRadio = {
                    ...radioDetail,
                    categories: radioDetail?.categories.map((c) => {
                        return c.id
                    }),
                    tags: radioDetail?.tags?.map((t) => {
                        return t.id
                    }),
                    idNetwork: data.singleNetwork
                        ? data.singleNetwork.id
                        : radioDetail.idNetwork === -1
                        ? null
                        : radioDetail.idNetwork,
                }
                const formData = new FormData()
                Object.keys(updatedRadio).map((key) => {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    const currentField = updatedRadio[key as any]
                    formData.append(key, Array.isArray(currentField) ? JSON.stringify(currentField) : currentField)
                    return
                })
                // console.log(formData.getAll('logo'), formData.getAll('name'))
                const newId = await FmWorldMultipartAxios.post(getRadios(), formData).then((res) => {
                    return res.data.id
                })
                dispatch(fetchRadios())
                setIsCreating(false)
                navigate(`${ROUTES.RADIO_DETAIL_ROUTE}${newId}`, {
                    state: {
                        name: updatedRadio.name,
                        idNetwork: data.singleNetwork ? data.singleNetwork.id : radioDetail.idNetwork,
                    },
                })
            } catch (error: any) {
                console.log('ERROR', error)
                setIsCreating(false)
                setError(error.toString())
            }
        }
    }

    const deleteRadio = async () => {
        if (radioDetail) {
            setError('')
            setIsLoading(true)
            try {
                await FmWorldAxios.delete(getRadioDetail(parseInt(props.idRadio ?? '', 10)))
                dispatch(fetchRadios())
                navigate(ROUTES.RADIO_LIST_ROUTE)
            } catch (error: any) {
                console.log('ERROR', error)
                setIsLoading(false)
                setError(error.toString())
            }
        }
    }

    if (isLoading) {
        return (
            <div className="loadingGrid">
                <Loader />
            </div>
        )
    }

    return (
        <div>
            <div className="titleRow">
                <div style={{ flex: 1 }}>
                    <h2 className="title">{t('radios.radio_details')}</h2>
                    <h3 className="subtitle" style={{ fontWeight: 400 }}>
                        {t('radios.radio_details_manage')}
                    </h3>
                    {error && <p className="u-normal-text u-bold-text u-error-text">{error}</p>}
                </div>
                <div style={{ display: 'flex', flex: 2, gap: 10, alignItems: 'center', justifyContent: 'flex-end' }}>
                    <div style={{ flex: 3 }} />
                    {props.permissions.includes(Grants.MANAGE_SYSTEM) ? (
                        <div style={{ flex: 1 }}>
                            {props.idRadio && props.idRadio !== 'new' ? (
                                <Button
                                    label={`${t('general.delete')} ${t('radios.radio')}`}
                                    onPress={() => {
                                        setShowModalDelete(true)
                                    }}
                                    type="submit"
                                    background="#fff"
                                    border={`2px solid ${COLORS.redDelete}`}
                                    color={COLORS.redDelete}
                                />
                            ) : null}
                        </div>
                    ) : null}
                    {props.permissions.includes(Grants.EDIT_RADIO) || props.idRadio === 'new' ? (
                        <div style={{ flex: 1 }}>
                            {props.idRadio && props.idRadio !== 'new' ? (
                                <Button
                                    disabled={dataIsInvalid}
                                    label={t('general.update')}
                                    onPress={() => {
                                        void editRadio()
                                    }}
                                    type="submit"
                                />
                            ) : (
                                <Button
                                    label={t('general.add')}
                                    onPress={() => {
                                        void createRadio()
                                    }}
                                    disabled={dataIsInvalid}
                                    type="submit"
                                />
                            )}
                        </div>
                    ) : null}
                </div>
            </div>
            {radioDetail ? (
                <div>
                    <div style={{ borderBottom: '2px solid rgba(0,0,0,0.07)', padding: '20px 0' }}>
                        <div className="u-elements-row">
                            <div className="uploadImage">
                                <img
                                    ref={logoRef}
                                    alt="logo1"
                                    src={radioDetail.logo ? (radioDetail.logo as string) : PLACEHOLDER}
                                    className="uploadImage-logo"
                                    onError={(e: any) => {
                                        e.currentTarget.src = PLACEHOLDER
                                    }}
                                />
                                {props.permissions.includes(Grants.EDIT_RADIO) || props.idRadio === 'new' ? (
                                    <input
                                        className="uploadImage-upload"
                                        type="file"
                                        accept="image/*"
                                        onChange={(event) =>
                                            handleInsertLogo(event, logoRef, (img) => {
                                                setRadioDetail({
                                                    ...radioDetail,
                                                    logo: img,
                                                })
                                            })
                                        }
                                    />
                                ) : null}
                            </div>
                            <p className="captionImg">
                                {t('radios.customize_logo_radio')}*
                                <br />({t('radios.500_500_px')}).
                            </p>
                        </div>
                        <div className="u-elements-row">
                            <TextInput
                                label={`${t('general.name')}*`}
                                value={radioDetail.name}
                                onTextChange={(text) => {
                                    setRadioDetail({
                                        ...radioDetail,
                                        name: text,
                                    })
                                }}
                                bordered
                                disabled={!props.permissions.includes(Grants.EDIT_RADIO) && props.idRadio !== 'new'}
                            />
                            <TextInput
                                label={t('radios.payoff')}
                                value={radioDetail.slogan}
                                onTextChange={(text) => {
                                    setRadioDetail({
                                        ...radioDetail,
                                        slogan: text,
                                    })
                                }}
                                bordered
                                disabled={!props.permissions.includes(Grants.EDIT_RADIO) && props.idRadio !== 'new'}
                            />
                            <div style={{ flex: 1 }} />
                        </div>
                    </div>
                    <div style={{ padding: '20px 0' }} className="u-elements-row">
                        <TextInput
                            label={t('radios.website')}
                            value={radioDetail.website}
                            onTextChange={(text) => {
                                setRadioDetail({
                                    ...radioDetail,
                                    website: text,
                                })
                            }}
                            bordered
                            disabled={!props.permissions.includes(Grants.EDIT_RADIO) && props.idRadio !== 'new'}
                        />
                        <TextInput
                            label={`${t('auth.email')}*`}
                            value={radioDetail.email}
                            onTextChange={(text) => {
                                setRadioDetail({
                                    ...radioDetail,
                                    email: text,
                                })
                            }}
                            borderColor={
                                radioDetail.email && !emailRegEx.test(radioDetail.email) ? COLORS.redDelete : ''
                            }
                            bordered
                            disabled={!props.permissions.includes(Grants.EDIT_RADIO) && props.idRadio !== 'new'}
                        />
                        <TextInput
                            label={`${t('general.telephone')}`}
                            value={radioDetail.phone || ''}
                            onTextChange={(text) => {
                                setRadioDetail({
                                    ...radioDetail,
                                    phone: text,
                                })
                            }}
                            borderColor={
                                radioDetail.phone && !phoneRegEx.test(radioDetail.phone) ? COLORS.redDelete : ''
                            }
                            bordered
                            disabled={!props.permissions.includes(Grants.EDIT_RADIO) && props.idRadio !== 'new'}
                        />
                        <TextInput
                            label={t('general.facebook')}
                            value={radioDetail.socialContacts || ''}
                            onTextChange={(text) => {
                                setRadioDetail({
                                    ...radioDetail,
                                    socialContacts: text,
                                })
                            }}
                            bordered
                            disabled={!props.permissions.includes(Grants.EDIT_RADIO) && props.idRadio !== 'new'}
                            isHidden
                            // hyde
                        />
                    </div>
                    <div style={{ padding: '20px 0' }} className="u-elements-row">
                        {data.radioTypes.length > 0 ? (
                            <SelectInput
                                label={`${t('radios.typology')}`}
                                placeholder={t('placeholders.select_typology')}
                                value={currentRadioType}
                                options={data.radioTypes.map((r) => {
                                    return {
                                        id: r.id,
                                        value: r.id,
                                        label: r.value,
                                    }
                                })}
                                onChange={(value) => {
                                    const v = value as Option
                                    if (v) {
                                        setRadioDetail({
                                            ...radioDetail,
                                            idRadioType: v.id as number,
                                        })
                                    }
                                }}
                                bordered
                                disabled={!props.permissions.includes(Grants.EDIT_RADIO) && props.idRadio !== 'new'}
                            />
                        ) : null}
                        {data.countries.length > 0 ? (
                            <SelectInput
                                label={`${t('radios.country')}`}
                                placeholder={t('placeholders.select_country')}
                                value={currentCountry}
                                options={data.countries.map((r) => {
                                    return {
                                        id: r.id,
                                        value: r.id,
                                        label: r.name,
                                    }
                                })}
                                onChange={(value) => {
                                    const v = value as Option
                                    if (v) {
                                        setRadioDetail({
                                            ...radioDetail,
                                            idCountry: v.id as string,
                                        })
                                    }
                                }}
                                bordered
                                disabled={!props.permissions.includes(Grants.EDIT_RADIO) && props.idRadio !== 'new'}
                            />
                        ) : null}
                        {data.categories.length > 0 ? (
                            <SelectInput
                                label={`${t('radios.categories')}`}
                                placeholder={t('placeholders.select_categories')}
                                flex={1}
                                isMulti
                                value={currentCategories}
                                options={data.categories.map((r) => {
                                    return {
                                        id: r.id,
                                        value: r.id,
                                        label: r.value,
                                    }
                                })}
                                onChange={(value) => {
                                    const values = value as Option[]
                                    if (values) {
                                        const rendered: Category[] = values.map((v) => {
                                            return {
                                                id: v.id as number,
                                                value: v.label,
                                            }
                                        })
                                        setRadioDetail({
                                            ...radioDetail,
                                            categories: rendered,
                                        })
                                    }
                                }}
                                bordered
                                disabled={!props.permissions.includes(Grants.EDIT_RADIO) && props.idRadio !== 'new'}
                            />
                        ) : null}
                        {data.radioTags.length > 0 ? (
                            <SelectInput
                                label={`${t('radios.tags')}`}
                                placeholder={t('placeholders.select_tags')}
                                flex={1}
                                isMulti
                                value={currentTags}
                                options={data.radioTags.map((r) => {
                                    return {
                                        id: r.id,
                                        value: r.id,
                                        label: r.value,
                                    }
                                })}
                                onChange={(value) => {
                                    const values = value as Option[]
                                    if (values) {
                                        const rendered: Tag[] = values.map((v) => {
                                            return {
                                                id: v.id as number,
                                                value: v.label,
                                            }
                                        })
                                        setRadioDetail({
                                            ...radioDetail,
                                            tags: rendered,
                                        })
                                    }
                                }}
                                bordered
                                disabled={!props.permissions.includes(Grants.EDIT_RADIO) && props.idRadio !== 'new'}
                                isHidden
                            />
                        ) : null}
                    </div>
                    <div style={{ padding: '20px 0' }} className="u-elements-row">
                        <TextInput
                            label={t('radios.latitude')}
                            value={radioDetail.latitude?.toString() || ''}
                            onTextChange={(text) => {
                                setRadioDetail({
                                    ...radioDetail,
                                    latitude: text ? parseFloat(text) : null,
                                })
                            }}
                            bordered
                            disabled={!props.permissions.includes(Grants.EDIT_RADIO) && props.idRadio !== 'new'}
                            isHidden
                        />
                        <TextInput
                            label={t('radios.longitude')}
                            value={radioDetail.longitude?.toString() || ''}
                            onTextChange={(text) => {
                                setRadioDetail({
                                    ...radioDetail,
                                    longitude: text ? parseFloat(text) : null,
                                })
                            }}
                            bordered
                            disabled={!props.permissions.includes(Grants.EDIT_RADIO) && props.idRadio !== 'new'}
                            isHidden
                        />
                    </div>
                    <div style={{ padding: '20px 0' }} className="u-elements-row">
                        <UploadInput
                            value={radioDetail.ownerAuthorization ? radioDetail.ownerAuthorization : null}
                            link={radioDetail.ownerAuthorization as string}
                            label={t('radios.copyright')}
                            text={radioDetail.ownerAuthorization ? t('radios.view_copyright_doc') : ''}
                            onChange={(file) => {
                                setRadioDetail({
                                    ...radioDetail,
                                    ownerAuthorization: file,
                                })
                            }}
                            disabled={!props.permissions.includes(Grants.EDIT_RADIO) && props.idRadio !== 'new'}
                        />
                        <CheckboxInput
                            values={[
                                {
                                    label: '',
                                    status: radioDetail.isPublic || true,
                                },
                            ]}
                            onClick={() => {
                                setRadioDetail({
                                    ...radioDetail,
                                    isPublic: !radioDetail.isPublic,
                                })
                            }}
                            label={t('radios.public_radio')}
                            isHidden
                            disabled={!props.permissions.includes(Grants.EDIT_RADIO) && props.idRadio !== 'new'}
                        />
                        <div style={{ flex: 2 }}>
                            {loggedUser.user?.idRole === 1 ? (
                                !data.singleNetwork ? (
                                    props.idNetwork === undefined || props.idNetwork === null ? (
                                        <SelectInput
                                            label={t('radios.network')}
                                            placeholder={t('placeholders.select_network')}
                                            value={networkValue()}
                                            options={networks}
                                            onChange={(v) => {
                                                const val = v as Option
                                                setRadioDetail({
                                                    ...radioDetail,
                                                    idNetwork: val.id as number,
                                                })
                                            }}
                                            disabled={
                                                !props.permissions.includes(Grants.EDIT_RADIO) &&
                                                props.idRadio !== 'new'
                                            }
                                        />
                                    ) : (
                                        <SelectInput
                                            label={t('radios.network')}
                                            placeholder={t('placeholders.select_network')}
                                            value={networkValue()?.id === props.idNetwork ? networkValue() : null}
                                            // eslint-disable-next-line react/jsx-props-no-multi-spaces
                                            options={networks}
                                            onChange={(v) => {
                                                const val = v as Option
                                                setRadioDetail({
                                                    ...radioDetail,
                                                    idNetwork: val.id as number,
                                                })
                                            }}
                                            bordered
                                        />
                                    )
                                ) : null
                            ) : null}
                        </div>
                        {props.permissions.includes(Grants.MANAGE_SYSTEM) ? (
                            <CheckboxInput
                                values={[
                                    {
                                        label: '',
                                        status: !!radioDetail.verifiedOn,
                                    },
                                ]}
                                onClick={() => {
                                    setRadioDetail({
                                        ...radioDetail,
                                        verifiedOn: radioDetail.verifiedOn ? null : new Date().toISOString(),
                                    })
                                }}
                                label={t('radios.verify')}
                            />
                        ) : null}
                        <div style={{ flex: 1 }} />
                    </div>
                    <h3
                        style={{
                            fontWeight: 700,
                            color: 'rgba(104,105,109,0.45)',
                            textTransform: 'uppercase',
                            fontSize: 14,
                            marginTop: 30,
                            marginBottom: 20,
                            display: 'none',
                        }}
                    >
                        {t('radios.app_banners')}
                    </h3>

                    <div
                        style={{ paddingBottom: 20, justifyContent: 'space-between', display: 'none' }}
                        className="u-elements-row"
                    >
                        <div className="bannerContainer">
                            <div className="uploadImage">
                                <img
                                    ref={imageH2Ref}
                                    alt="logo2"
                                    src={radioDetail.imageH2 ? (radioDetail.imageH2 as string) : PLACEHOLDER}
                                    className="uploadImage-imageH2"
                                    onError={(e: any) => {
                                        e.currentTarget.src = PLACEHOLDER
                                    }}
                                />
                                {props.permissions.includes(Grants.EDIT_RADIO) || props.idRadio === 'new' ? (
                                    <input
                                        className="uploadImage-upload"
                                        type="file"
                                        accept="image/*"
                                        onChange={(event) =>
                                            handleInsertLogo(event, imageH2Ref, (img) => {
                                                setRadioDetail({
                                                    ...radioDetail,
                                                    imageH2: img,
                                                })
                                            })
                                        }
                                    />
                                ) : null}
                            </div>
                            <p className="captionImg">
                                Banner 1
                                <br />
                                (600 x 500 px).
                            </p>
                        </div>
                        <div className="bannerContainer">
                            <div className="uploadImage">
                                <img
                                    ref={imageH3Ref}
                                    alt="logo3"
                                    src={radioDetail.imageH3 ? (radioDetail.imageH3 as string) : PLACEHOLDER}
                                    className="uploadImage-imageH3"
                                    onError={(e: any) => {
                                        e.currentTarget.src = PLACEHOLDER
                                    }}
                                />
                                {props.permissions.includes(Grants.EDIT_RADIO) || props.idRadio === 'new' ? (
                                    <input
                                        className="uploadImage-upload"
                                        type="file"
                                        accept="image/*"
                                        onChange={(event) =>
                                            handleInsertLogo(event, imageH3Ref, (img) => {
                                                setRadioDetail({
                                                    ...radioDetail,
                                                    imageH3: img,
                                                })
                                            })
                                        }
                                    />
                                ) : null}
                            </div>
                            <p className="captionImg">
                                Banner 2
                                <br />
                                (750 x 500 px).
                            </p>
                        </div>
                        <div className="bannerContainer">
                            <div className="uploadImage">
                                <img
                                    ref={imageH4Ref}
                                    alt="logo4"
                                    src={radioDetail.imageH4 ? (radioDetail.imageH4 as string) : PLACEHOLDER}
                                    className="uploadImage-imageH4"
                                    onError={(e: any) => {
                                        e.currentTarget.src = PLACEHOLDER
                                    }}
                                />
                                {props.permissions.includes(Grants.EDIT_RADIO) || props.idRadio === 'new' ? (
                                    <input
                                        className="uploadImage-upload"
                                        type="file"
                                        accept="image/*"
                                        onChange={(event) =>
                                            handleInsertLogo(event, imageH4Ref, (img) => {
                                                setRadioDetail({
                                                    ...radioDetail,
                                                    imageH4: img,
                                                })
                                            })
                                        }
                                    />
                                ) : null}
                            </div>
                            <p className="captionImg">
                                Banner 3
                                <br />
                                (1000 x 500 px).
                            </p>
                        </div>
                    </div>
                </div>
            ) : null}
            <LoadingModal
                isVisible={isCreating || isUpdating}
                text={isUpdating ? 'Sto aggiornando la radio...' : 'Sto creando la radio...'}
            />
            <DeleteModal
                onDelete={() => {
                    void deleteRadio()
                }}
                title={`Elimina radio ${radioDetail?.name}`}
                text={`Vuoi eliminare la radio ${radioDetail?.name}?`}
                onClose={() => {
                    setShowModalDelete(false)
                }}
                isVisible={showModalDelete}
            />
        </div>
    )
}

export default RadioDetailTab
