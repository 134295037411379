import React, { useState } from 'react'
import './Modals.sass'
import Modal from '@mui/material/Modal'
import Button from '../Button/Button'
import TextInput from '../TextInput/TextInput'
import { FormatGroup } from '../../pages/FormatGroupPage/FormatGroupPage'

interface FormatGroupModalProps {
    isVisible: boolean
    onClose: () => void
    onSave: (formatGroup: { id: number; name?: string }) => void
    formatGroup?: FormatGroup | null
}

const FormatGroupModal: React.FC<FormatGroupModalProps> = (props) => {
    const [name, setName] = useState(props.formatGroup?.name || '')
    const id = props.formatGroup?.id || 0

    const handleSave = () => {
        props.onSave({ id, name })
    }

    return (
        <Modal
            open={props.isVisible}
            onClose={() => props.onClose()}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <div className="modalBox formatGroupBox">
                <h2 className="modalBox-title">
                    {props.formatGroup ? 'Modifica Gruppo di Formato' : 'Aggiungi Gruppo di Formato'}
                </h2>
                <div className="formatGroupBox-content">
                    <TextInput value={name} onTextChange={setName} placeholder="Nome del Gruppo" />
                    <div className="formatGroupBox-actions">
                        <Button label="Salva" onPress={handleSave} type={'submit'} />
                        <Button label="Annulla" onPress={props.onClose} type={'submit'} />
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default FormatGroupModal
