import React, { useState, useEffect } from 'react'
import './Modals.sass'
import Modal from '@mui/material/Modal'
import Button from '../Button/Button'
import AsyncSelectInput from '../SelectInput/AsyncSelectInput'
import { Option } from '../SelectInput/SelectInput'
import { getAvailableRadios } from '../../resources/api-constants'
import FmWorldAxios from '../../utility/FmWorldAxios'
import RadioFilter, { RadioFilterObject } from '../Filters/RadioFilter'
import { useTranslation } from 'react-i18next'
import { COLORS } from '../../resources/colors'
import { Radio } from '../../types/data'

interface RadioModalProps {
    isVisible: boolean
    onClose: () => void
    onSave: (radio: { id: number }) => void
    radio?: Radio | null
    formatGroupId: number
}

const initialFilter: RadioFilterObject = {
    countries: [],
    categories: [],
    types: [],
    videoOnly: false,
    returnEmptyStreams: true,
    returnOfflineStreams: true,
    networks: [],
    returnVerifiedStreams: false,
}

const RadioModal: React.FC<RadioModalProps> = (props) => {
    const [selectedRadio, setSelectedRadio] = useState<Option | null>(null)
    const [filter, setFilter] = useState<RadioFilterObject>(initialFilter)
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
    const [error, setError] = useState<string>('')
    const { t } = useTranslation()

    useEffect(() => {
        if (props.radio) {
            setSelectedRadio({ id: props.radio.id, value: props.radio.id, label: props.radio.name })
        } else {
            setSelectedRadio(null)
        }
    }, [props.radio])

    const handleSave = () => {
        if (!selectedRadio) {
            return
        }
        props.onSave({ id: selectedRadio.value as number })
    }

    const handleFilter = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget)
    }

    const setFilterParams = () => {
        const localFilter: any = {
            videoOnly: filter.videoOnly,
            returnEmptyStreams: filter.returnEmptyStreams,
            returnOfflineStreams: filter.returnOfflineStreams,
            returnVerifiedStreams: filter.returnVerifiedStreams,
        }
        if (filter.countries && filter.countries.length > 0) {
            localFilter.countries = filter.countries.toString()
        }
        if (filter.categories && filter.categories.length > 0) {
            localFilter.categories = filter.categories.toString()
        }
        if (filter.types && filter.types.length > 0) {
            localFilter.types = filter.types.toString()
        }
        if (filter.networks && filter.networks.length > 0) {
            localFilter.networks = filter.networks.toString()
        }
        return localFilter
    }

    const fetchRadioOptions = async (inputValue: string): Promise<Option[]> => {
        if (!inputValue || inputValue.length < 3) return []
        try {
            const params = { ...setFilterParams(), query: inputValue }
            const response = await FmWorldAxios.get(getAvailableRadios(props.formatGroupId), { params })
            const radios = response.data.items

            if (!Array.isArray(radios)) {
                console.error('Expected radios to be an array, but got:', radios)
                return []
            }

            const options = radios.map((radio: Radio) => ({
                id: radio.id,
                value: radio.id,
                label: radio.name,
            }))

            return options
        } catch (error) {
            console.error('Error fetching radios:', error)
            setError(t('errors.fetching_radios') || 'Error fetching radios')
            return []
        }
    }

    return (
        <Modal
            open={props.isVisible}
            onClose={props.onClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <div className="modalBox radioBox">
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: 15 }}>
                    <h2 className="modalBox-title">{props.radio ? t('radios.edit_radio') : t('radios.add_radio')}</h2>
                    <div>
                        <Button
                            label={t('radios.filter_radio')}
                            onPress={handleFilter}
                            type="submit"
                            background="#fff"
                            border={`2px solid ${
                                JSON.stringify(filter) !== JSON.stringify(initialFilter) ? COLORS.green : COLORS.purple
                            }`}
                            color={
                                JSON.stringify(filter) !== JSON.stringify(initialFilter) ? COLORS.green : COLORS.purple
                            }
                        />
                        <RadioFilter
                            filter={filter}
                            anchorEl={anchorEl}
                            onClose={() => setAnchorEl(null)}
                            onReset={() => {
                                setFilter(initialFilter)
                            }}
                            onUpdate={(f) => setFilter(f)}
                            position={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                        />
                    </div>
                </div>
                {error && <p className="u-normal-text u-bold-text u-error-text">{error}</p>}
                <div className="radioBox-content">
                    <AsyncSelectInput
                        value={selectedRadio}
                        onChange={(value) => setSelectedRadio(value as Option)}
                        fetchOptions={fetchRadioOptions}
                        placeholder={t('placeholders.select_radio')}
                    />
                    <div className="radioBox-actions">
                        <Button label={t('general.cancel')} onPress={props.onClose} background="#ccc" type={'submit'} />
                        <Button
                            label={t('general.save')}
                            onPress={handleSave}
                            type={'submit'}
                            disabled={!selectedRadio}
                        />
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default RadioModal
