import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import Button from '../../components/Button/Button'
import CardContainer from '../../components/CardContainer/CardContainer'
import LoadingModal from '../../components/Modals/LoadingModal'
import PageSidebarContainer from '../../components/PageSidebarContainer'
import { clearRedisCache } from '../../resources/api-constants'
import { COLORS } from '../../resources/colors'
import { setPageHeader } from '../../store/actions/graphic'
import { Reducers, ReducerUser } from '../../types/reducers'
import FmWorldAxios from '../../utility/FmWorldAxios'
import { Grants } from '../../utility/Grants'
import './SettingsPage.sass'

const SettingsPage: React.FC = () => {
    const user = useSelector<Reducers, ReducerUser>((state) => state.user)
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState('')
    const { t } = useTranslation()
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(
            setPageHeader({
                parent: null,
                name: t('headers.settings'),
            })
        )
    }, [])

    const handleDeleteCache = async () => {
        setIsLoading(true)
        try {
            await FmWorldAxios.post(clearRedisCache())
        } catch (error: any) {
            setError(error.toString())
        }
        setIsLoading(false)
    }

    return (
        <PageSidebarContainer>
            <CardContainer>
                <div className="titleRow">
                    <div>
                        <h2 className="title">{t('settings.your_settings')}</h2>
                        <h3 className="subtitle" style={{ fontWeight: 400 }}>
                            {t('settings.manage_settings')}
                        </h3>
                    </div>
                </div>
                {user.user && user.user.grants.includes(Grants.MANAGE_SYSTEM) ? (
                    <div>
                        <h3 className="titleSetting">{t('settings.system_settings')}</h3>
                        <div className="setting">
                            <div style={{ width: '100%', flex: 2 }}>
                                <p>{t('settings.delete_redis_cache')}</p>
                                {error && <p className="u-normal-text u-bold-text u-error-text">{error}</p>}
                            </div>
                            <Button
                                label={t('settings.delete_cache')}
                                onPress={() => {
                                    void handleDeleteCache()
                                }}
                                type="submit"
                                background="#fff"
                                border={`2px solid ${COLORS.redDelete}`}
                                color={COLORS.redDelete}
                                width={'25%'}
                            />
                        </div>
                    </div>
                ) : null}
            </CardContainer>
            <LoadingModal isVisible={isLoading} text={t('loadings.generic_loading')} />
        </PageSidebarContainer>
    )
}

export default SettingsPage
