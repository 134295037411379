import { initializeApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'

const firebaseConfigDevelopment = {
    apiKey: 'AIzaSyD4QQcUfc7NMR0gsyjwlqr7KFPW5VNiov8',
    authDomain: 'fm-world-5d77d.firebaseapp.com',
    databaseURL: 'https://fm-world-5d77d.firebaseio.com',
    projectId: 'fm-world-5d77d',
    storageBucket: 'fm-world-5d77d.appspot.com',
    messagingSenderId: '594205530532',
    appId: '1:594205530532:web:f51313b41f7e6ae09c4931',
    measurementId: 'G-QMKYZ81TPJ',
}

const firebaseConfigProduction = {
    apiKey: 'AIzaSyD4QQcUfc7NMR0gsyjwlqr7KFPW5VNiov8',
    authDomain: 'fm-world-5d77d.firebaseapp.com',
    databaseURL: 'https://fm-world-5d77d.firebaseio.com',
    projectId: 'fm-world-5d77d',
    storageBucket: 'fm-world-5d77d.appspot.com',
    messagingSenderId: '594205530532',
    appId: '1:594205530532:web:f51313b41f7e6ae09c4931',
    measurementId: 'G-QMKYZ81TPJ',
}

const configFirebase = process.env.NODE_ENV === 'production' ? firebaseConfigProduction : firebaseConfigDevelopment

const firebase = initializeApp(configFirebase)

export const auth = getAuth(firebase)

export default firebase
