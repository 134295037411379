import { PopoverOrigin } from '@mui/material/Popover'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { getNetworks } from '../../resources/api-constants'
import { Network } from '../../types/data'
import { ReducerData, Reducers } from '../../types/reducers'
import FmWorldAxios from '../../utility/FmWorldAxios'
import CheckboxInput from '../CheckboxInput/CheckboxInput'
import SelectInput, { Option } from '../SelectInput/SelectInput'
import FilterPopover from './FilterPopover'

export interface RadioFilterObject {
    query?: string
    countries: string[]
    categories: number[]
    types: number[]
    videoOnly: boolean
    returnEmptyStreams: boolean
    returnOfflineStreams: boolean
    networks: number[]
    returnVerifiedStreams: boolean
}

interface Props {
    filter: RadioFilterObject
    anchorEl: HTMLButtonElement | null
    position?: PopoverOrigin
    onClose: () => void
    onReset: () => void
    onUpdate: (f: RadioFilterObject) => void
}

const RadioFilter: React.FC<Props> = (props) => {
    const data = useSelector<Reducers, ReducerData>((state) => state.data)
    const [filter, setFilter] = useState<RadioFilterObject>(props.filter)
    const [networks, setNetworks] = useState<Network[]>([])
    const { t } = useTranslation()

    const fetchNetworks = async () => {
        try {
            const networksFetch = await FmWorldAxios.get(getNetworks()).then((res) => {
                return res.data.items
            })
            setNetworks(networksFetch)
        } catch (error) {
            console.log('ERRORE', error)
        }
    }

    useEffect(() => {
        if (!data.singleNetwork) {
            void fetchNetworks()
        }
    }, [])

    useEffect(() => {
        if (props.anchorEl) {
            setFilter(props.filter)
        }
    }, [props.anchorEl])

    const countriesValue = () => {
        const options: Option[] = []
        filter.countries.forEach((c) => {
            const found = data.countries.find((country) => country.id === c)
            if (found) {
                options.push({
                    id: found.id,
                    value: found.id,
                    label: found.name,
                })
            }
        })
        return options
    }

    const typesValue = () => {
        const options: Option[] = []
        filter.types.forEach((c) => {
            const found = data.radioTypes.find((type) => type.id === c)
            if (found) {
                options.push({
                    id: found.id,
                    value: found.id,
                    label: found.value,
                })
            }
        })
        return options
    }

    const categoriesValue = () => {
        const options: Option[] = []
        filter.categories.forEach((c) => {
            const found = data.categories.find((cat) => cat.id === c)
            if (found) {
                options.push({
                    id: found.id,
                    value: found.id,
                    label: found.value,
                })
            }
        })
        return options
    }

    const networksValue = () => {
        const options: Option[] = []
        filter.networks.forEach((c) => {
            const found = networks.find((network) => network.id === c)
            if (found) {
                options.push({
                    id: found.id,
                    value: found.id,
                    label: found.name,
                })
            }
        })
        return options
    }

    return (
        <FilterPopover
            anchorElem={props.anchorEl}
            onResetClick={() => props.onReset()}
            onFilterClick={() => props.onUpdate(filter)}
            onClose={() => props.onClose()}
            position={props.position ? props.position : null}
        >
            <div style={{ display: 'flex', flexDirection: 'column', gap: 15 }}>
                <SelectInput
                    options={data.countries.map((c) => {
                        return {
                            id: c.id,
                            value: c.id,
                            label: c.name,
                        }
                    })}
                    isMulti
                    value={countriesValue()}
                    label={t('general.countries')}
                    placeholder={t('placeholders.select_countries')}
                    onChange={(v) => {
                        const values = v as Option[]
                        setFilter({
                            ...filter,
                            countries: values.map((c) => {
                                return c.id as string
                            }),
                        })
                    }}
                />
                <SelectInput
                    options={data.radioTypes.map((c) => {
                        return {
                            id: c.id,
                            value: c.id,
                            label: c.value,
                        }
                    })}
                    isMulti
                    value={typesValue()}
                    label={t('radios.typology')}
                    placeholder={t('placeholders.select_typology')}
                    onChange={(v) => {
                        const values = v as Option[]
                        setFilter({
                            ...filter,
                            types: values.map((c) => {
                                return c.id as number
                            }),
                        })
                    }}
                />
                <SelectInput
                    options={data.categories.map((c) => {
                        return {
                            id: c.id,
                            value: c.id,
                            label: c.value,
                        }
                    })}
                    isMulti
                    value={categoriesValue()}
                    label={t('radios.categories')}
                    placeholder={t('placeholders.select_categories')}
                    onChange={(v) => {
                        const values = v as Option[]
                        setFilter({
                            ...filter,
                            categories: values.map((c) => {
                                return c.id as number
                            }),
                        })
                    }}
                />
                <SelectInput
                    options={networks.map((c) => {
                        return {
                            id: c.id,
                            value: c.id,
                            label: c.name,
                        }
                    })}
                    isMulti
                    value={networksValue()}
                    label={t('radios.network')}
                    placeholder={t('placeholders.select_network')}
                    onChange={(v) => {
                        const values = v as Option[]
                        setFilter({
                            ...filter,
                            networks: values.map((c) => {
                                return c.id as number
                            }),
                        })
                    }}
                />
                <CheckboxInput
                    values={[
                        {
                            label: '',
                            status: filter.videoOnly,
                        },
                    ]}
                    onClick={() => {
                        setFilter({
                            ...filter,
                            videoOnly: !filter.videoOnly,
                        })
                    }}
                    label={t('radios.video_only')}
                />
                <CheckboxInput
                    values={[
                        {
                            label: '',
                            status: filter.returnEmptyStreams,
                        },
                    ]}
                    onClick={() => {
                        setFilter({
                            ...filter,
                            returnEmptyStreams: !filter.returnEmptyStreams,
                        })
                    }}
                    label={t('radios.show_radios_without_streams')}
                />
                <CheckboxInput
                    values={[
                        {
                            label: '',
                            status: filter.returnOfflineStreams,
                        },
                    ]}
                    onClick={() => {
                        setFilter({
                            ...filter,
                            returnOfflineStreams: !filter.returnOfflineStreams,
                        })
                    }}
                    label={t('radios.show_only_offline_streams')}
                />
                <CheckboxInput
                    values={[
                        {
                            label: '',
                            status: filter.returnVerifiedStreams,
                        },
                    ]}
                    onClick={() => {
                        setFilter({
                            ...filter,
                            returnVerifiedStreams: !filter.returnVerifiedStreams,
                        })
                    }}
                    label={t('radios.show_only_verified_streams')}
                />
            </div>
        </FilterPopover>
    )
}

export default RadioFilter
