import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import packageInfo from '../../../package.json'
import LOGO from '../../resources/images/fmworld-logowhite.svg'
import { ROUTES } from '../../resources/routes-constants'
import { ReducerData, Reducers, ReducerUser } from '../../types/reducers'
import Icon from '../Icon'
import LanguageSelector from '../LanguageSelector/LanguageSelector'
import NotificationIcon from '../Notification/NotificationIcon'
import MenuItem from './Item/MenuItem'
import SectionTitle from './SectionTitle/SectionTitle'
import './Sidebar.sass'
import { Grants } from '../../utility/Grants'
import NetworkTabs from './NetworksTabs'

const Sidebar: React.FC = () => {
    const data = useSelector<Reducers, ReducerData>((state) => state.data)
    const user = useSelector<Reducers, ReducerUser>((state) => state.user)
    const navigate = useNavigate()
    const { t } = useTranslation()

    return (
        <div className="sidebar">
            <div className="sidebar-container">
                <img className="sidebar-container_logo" src={LOGO} alt="logo" />
                <div className="sidebar-container_menu">
                    <MenuItem name={t('sidemenus.home')} icon="home" path={ROUTES.HOMEPAGE_ROUTE} />
                    {user.user && user.user.grants && user.user.grants.includes(Grants.EDIT_NETWORK) ? (
                        <div style={{ margin: '20px 0' }}>
                            {user.user.networks && user.user.networks.length > 0 ? <NetworkTabs /> : null}
                        </div>
                    ) : null}

                    {user.user && user.user.grants && user.user.grants.includes(Grants.MANAGE_SYSTEM) ? (
                        <div style={{ margin: '20px 0' }}>
                            <SectionTitle text={t('sidemenus.contents')} />

                            <MenuItem
                                name={t('sidemenus.format_groups')}
                                icon=""
                                path={ROUTES.FORMAT_GROUP_LIST_ROUTE}
                                detailPath={'format_group'}
                            />

                            <MenuItem
                                name={t('sidemenus.pending_elements')}
                                icon=""
                                detailPath=""
                                path={ROUTES.RADIO_PENDING_LIST_ROUTE}
                            />
                            <MenuItem
                                name={t('sidemenus.radios')}
                                icon="radio"
                                detailPath="radio"
                                path={ROUTES.RADIO_LIST_ROUTE}
                                count={data.totalRadios ? data.totalRadios.toString() : '0'}
                            />
                            <MenuItem
                                name={t('sidemenus.frequencies')}
                                icon="frequenze"
                                path={ROUTES.FREQUENCY_LIST_ROUTE}
                                count={data.totalFrequencies ? data.totalFrequencies.toString() : '0'}
                            />
                            <MenuItem
                                name={t('sidemenus.dabs')}
                                icon="dab"
                                path={ROUTES.DAB_LIST_ROUTE}
                                sizeIcon={14}
                                marginRightIcon={12}
                                detailPath={'dab'}
                                count={data.totalDab ? data.totalDab.toString() : '0'}
                            />
                            {user.user && user.user.grants && user.user.grants.includes(Grants.MANAGE_SYSTEM) ? (
                                <MenuItem
                                    name={t('sidemenus.interactiveMux')}
                                    icon="dab"
                                    path={ROUTES.INTERACTIVE_MUX_ROUTE}
                                    sizeIcon={14}
                                    marginRightIcon={12}
                                    detailPath={'interactive_mux'}
                                    count={data.totalInteractiveMUX ? data.totalInteractiveMUX.toString() : '0'}
                                />
                            ) : null}
                        </div>
                    ) : null}
                </div>

                <div className="sidebar-container_profile">
                    <div className="sidebar-container_profile--avatar" onClick={() => navigate(ROUTES.USER_ROUTE)}>
                        <Icon name="user" color="#fff" size={30} />
                    </div>
                    <div className="sidebar-container_profile--data" onClick={() => navigate(ROUTES.USER_ROUTE)}>
                        <p style={{ color: 'rgba(82,86,90,0.8)', fontSize: 14, fontWeight: 600 }}>
                            {user.user?.first_name || 'User Area'} {user.user?.last_name || ''}
                        </p>
                    </div>
                    <NotificationIcon />
                </div>

                <div className="sidebar-container_copyright">
                    <div>
                        <LanguageSelector />
                    </div>
                    <div>
                        <a
                            href="mailto:info@fm-world.it"
                            target="_blank"
                            rel="noreferrer"
                            style={{ color: 'rgba(82,86,90,0.8)', textDecoration: 'underline', fontWeight: 600 }}
                        >
                            {t('sidemenus.signal_bug')}
                        </a>
                        <p style={{ color: 'rgba(82,86,90,0.5)', fontSize: 12, margin: '5px 0', fontWeight: 500 }}>
                            Made with ❤️ by 22HBG
                        </p>
                        <p style={{ color: 'rgba(82,86,90,0.5)', fontSize: 12, margin: '5px 0', fontWeight: 500 }}>
                            {t('sidemenus.version')} {packageInfo.version}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Sidebar
