import React from 'react'
import LOGO from '../resources/images/fmworld-logowhite.svg'
import Link from '../components/Link/Link'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import LanguageSelector from './LanguageSelector/LanguageSelector'

const StaticContentRegisterPage: React.FC = () => {
    const { t } = useTranslation()

    return (
        <div className="c-login-text-container">
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'flex-start',
                    flex: 1,
                    gap: 24,
                }}
            >
                <img src={LOGO} alt="logo" />
                <p className="u-large-text u-bold-text">{t('auth.world_radio_aggregator')}.</p>
                <p className="u-medium-text">{t('auth.first_paragraph_login')}</p>
                <p className="u-medium-text">{t('auth.second_paragraph_login')}</p>
            </div>

            <div className="u-elements-row u-small-gap">
                <LanguageSelector />
                <p className="u-small-text u-bold-text">22HBG - {moment().year()}</p>
                <Link
                    label={t('auth.terms_conditions')}
                    underline="always"
                    type="text-colored"
                    target="external"
                    href="#"
                />
                <Link
                    label={t('auth.privacy_policy')}
                    underline="always"
                    type="text-colored"
                    target="external"
                    href="#"
                />
            </div>
        </div>
    )
}

export default StaticContentRegisterPage
