export const COLORS = {
    purple: '#642677',
    darkGrey: '#52565A',
    blueGrey: '#677791',
    lightBlueGrey: '#B2BAC8',
    redDelete: '#C30000',
    lightGrey: '#C2C3C4',
    green: '#4AD9AA',
    lightPurpleBg: '#F5F5FB',
}
