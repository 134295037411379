import React, { useEffect, useState } from 'react'
import './Modals.sass'
import Modal from '@mui/material/Modal'
import { Network } from '../../types/data'
import TextInput from '../TextInput/TextInput'
import Button from '../Button/Button'
import { COLORS } from '../../resources/colors'
import { VoidNetwork } from '../../utility/voidConstants'
import { useTranslation } from 'react-i18next'
import { emailRegEx, phoneRegEx } from '../../utility/functions'

interface Props {
    isVisible: boolean
    onClose: () => void
    onSave: (newElement: Network | VoidNetwork) => void
    network: Network | VoidNetwork | null
    loading?: boolean
}

const NetworkModal: React.FC<Props> = (props) => {
    const [networkToEdit, setNetworkToEdit] = useState<Network | VoidNetwork | null>(props.network)
    const { t } = useTranslation()

    useEffect(() => {
        if (props.isVisible) {
            setNetworkToEdit(props.network)
        }
    }, [props.isVisible])

    return (
        <Modal
            open={props.isVisible}
            onClose={() => props.onClose()}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <div className="modalBox actionBox">
                <h2 className="modalBox-title">
                    {networkToEdit && (networkToEdit as Network).id
                        ? t('networks.edit_network')
                        : t('networks.create_network')}
                </h2>
                {networkToEdit ? (
                    <div>
                        <TextInput
                            value={networkToEdit.name}
                            label={t('general.name')}
                            onTextChange={(e) =>
                                setNetworkToEdit({
                                    ...networkToEdit,
                                    name: e,
                                })
                            }
                        />
                        <div style={{ marginTop: 20 }} className="u-elements-row">
                            <TextInput
                                value={networkToEdit.referentFirstName}
                                label={t('networks.contact_first_name')}
                                onTextChange={(e) =>
                                    setNetworkToEdit({
                                        ...networkToEdit,
                                        referentFirstName: e,
                                    })
                                }
                            />
                            <TextInput
                                value={networkToEdit.referentLastName}
                                label={t('networks.contact_last_name')}
                                onTextChange={(e) =>
                                    setNetworkToEdit({
                                        ...networkToEdit,
                                        referentLastName: e,
                                    })
                                }
                            />
                        </div>
                        <div style={{ margin: '20px 0' }} className="u-elements-row">
                            <TextInput
                                value={networkToEdit.email}
                                label={t('networks.contact_email')}
                                onTextChange={(e) =>
                                    setNetworkToEdit({
                                        ...networkToEdit,
                                        email: e,
                                    })
                                }
                            />
                            <TextInput
                                value={networkToEdit.phoneNumber}
                                label={t('networks.contact_phone')}
                                onTextChange={(e) =>
                                    setNetworkToEdit({
                                        ...networkToEdit,
                                        phoneNumber: e,
                                    })
                                }
                            />
                        </div>
                    </div>
                ) : null}
                <div className="modalBox-buttonsRow">
                    <div style={{ flex: '1 1 100%' }} />
                    <Button
                        label={t('general.cancel')}
                        border={'1px solid rgba(0,0,0,0.07)'}
                        onPress={() => props.onClose()}
                        type="secondary"
                        color={COLORS.darkGrey}
                        background="#fff"
                        disabled={props.loading}
                    />
                    <Button
                        disabled={
                            !networkToEdit ||
                            !networkToEdit?.name ||
                            !networkToEdit.referentFirstName ||
                            !networkToEdit.email ||
                            !emailRegEx.test(networkToEdit.email) ||
                            !networkToEdit.phoneNumber ||
                            !phoneRegEx.test(networkToEdit.phoneNumber) ||
                            !networkToEdit.referentLastName || props.loading
                        }
                        label={networkToEdit && (networkToEdit as Network).id ? t('general.update') : t('general.add')}
                        onPress={() => props.onSave(networkToEdit as Network)}
                        type="submit"
                        color="#fff"
                        background={COLORS.purple}
                    />
                </div>
            </div>
        </Modal>
    )
}

export default NetworkModal
