import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Button from '../../components/Button/Button'
import Link from '../../components/Link/Link'
import LoadingModal from '../../components/Modals/LoadingModal'
import StaticContentRegisterPage from '../../components/StaticContentRegisterPage'
import TextInput from '../../components/TextInput/TextInput'
import { COLORS } from '../../resources/colors'
import { ROUTES } from '../../resources/routes-constants'
import { setLoggedUserData } from '../../store/actions/user'
import { ReducerGraphic, Reducers } from '../../types/reducers'
import FmWorldAxios from '../../utility/FmWorldAxios'
import { emailRegEx, passwordRegEx } from '../../utility/functions'
import './../../styles/pages/LoginPage.sass'
import { getCurrentFMWorldUser, logFmWorldUser } from '../../resources/api-constants'
import { createUserWithEmailAndPassword } from 'firebase/auth'
import { auth } from '../../utility/configFirebase'
import FmWorldMultipartAxios from '../../utility/FmWorldMultipartAxios'
import CustomAxios from '../../utility/CustomAxios'
import { AUTH_PROVIDER, performSocialLogin } from '../../services/firebase'
import GoogleLogo from '../../resources/images/google-logo.png'
import AppleLogo from '../../resources/images/apple-logo.png'

const RegisterPage: React.FC = () => {
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const [registerError, setRegisterError] = useState('')
    const navigate = useNavigate()
    const dispatch = useDispatch()
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const graphic = useSelector<Reducers, ReducerGraphic>((state) => state.graphic)
    const { t } = useTranslation()

    const registerUser = async () => {
        setIsLoading(true)
        try {
            const user = (await createUserWithEmailAndPassword(auth, email, password).then((res) => res.user)) as any

            const userToken = user.accessToken

            const userData = await logFmWorldUser(userToken)

            FmWorldAxios.defaults.headers.common.Authorization = `Bearer ${userToken}`
            CustomAxios.defaults.headers.common.Authorization = `Bearer ${userToken}`

            dispatch(
                setLoggedUserData({
                    ...userData,
                    authToken: userToken,
                    refreshToken: user.stsTokenManager.refreshToken,
                })
            )

            await FmWorldMultipartAxios.put(`/users/${userToken}`, auth)

            navigate(`${ROUTES.HOMEPAGE_ROUTE}`)
        } catch (error: any) {
            console.error(error)
            setRegisterError(error.toString())
        }
        setIsLoading(false)
    }

    const socialLogin = async (provider: AUTH_PROVIDER) => {
        const loginResult = await performSocialLogin(provider)
        if (!loginResult) return

        const userToken = await loginResult.getIdToken(false)

        FmWorldAxios.defaults.headers.common.Authorization = `Bearer ${userToken}`
        CustomAxios.defaults.headers.common.Authorization = `Bearer ${userToken}`

        await logFmWorldUser(userToken)
        const userInfo = await getCurrentFMWorldUser(userToken)

        dispatch(setLoggedUserData({ ...userInfo, authToken: userToken }))

        navigate(`${ROUTES.HOMEPAGE_ROUTE}`)
    }

    const checkDataIsValid = useMemo(() => {
        return (
            !isLoading &&
            email &&
            emailRegEx.test(email) &&
            password &&
            passwordRegEx.test(password) &&
            password === confirmPassword
        )
    }, [email, password, confirmPassword, isLoading])

    return (
        <div className="u-full-page-container u-row-page" style={{ gap: 0 }}>
            <div className="c-login-interactive-panel">
                <div className="c-login-interactive-panel__main-panel">
                    <div className="o-login-inputs-container">
                        <p className="u-medium-text u-bold-text">{t('auth.registration')}</p>
                        <div className="social-buttons-container">
                            <button
                                className="base-btn form-btn"
                                onClick={() => {
                                    void socialLogin(AUTH_PROVIDER.GOOGLE)
                                }}
                            >
                                <img className="form-btn__img" src={GoogleLogo} alt="Google" />
                                {t('auth.login_with_google')}
                            </button>
                            <button
                                className="base-btn form-btn"
                                onClick={() => {
                                    void socialLogin(AUTH_PROVIDER.APPLE)
                                }}
                            >
                                <img className="form-btn__img" src={AppleLogo} alt="Apple" />
                                {t('auth.login_with_apple')}
                            </button>
                        </div>
                        <div className="u-normal-text">
                            {t('auth.have_account')}{' '}
                            <Link
                                label={t('auth.logon')}
                                underline="always"
                                type="accent"
                                target="internal"
                                targetUrl={{ url: ROUTES.LOGIN_ROUTE }}
                            />
                        </div>
                        <div className="u-elements-row u-small-gap">
                            <TextInput
                                value={firstName}
                                placeholder={t('auth.first_name')}
                                onTextChange={(newValue) => setFirstName(newValue)}
                            />
                            <TextInput
                                value={lastName}
                                placeholder={t('auth.last_name')}
                                onTextChange={(newValue) => setLastName(newValue)}
                            />
                        </div>
                        <TextInput
                            value={email}
                            placeholder={t('auth.email')}
                            borderColor={email && !emailRegEx.test(email) ? COLORS.redDelete : ''}
                            onTextChange={(newValue) => setEmail(newValue)}
                        />
                        <TextInput
                            value={username}
                            placeholder={t('auth.username')}
                            onTextChange={(newValue) => setUsername(newValue)}
                        />
                        <TextInput
                            type={'password'}
                            value={password}
                            placeholder={t('auth.password')}
                            borderColor={password && !passwordRegEx.test(password) ? COLORS.redDelete : ''}
                            onTextChange={(newValue) => setPassword(newValue)}
                        />
                        <p className="u-normal-text">{t('auth.password_advice')}</p>
                        <TextInput
                            type={'password'}
                            value={confirmPassword}
                            placeholder={t('auth.confirm_password')}
                            borderColor={password && password !== confirmPassword ? COLORS.redDelete : ''}
                            onTextChange={(newValue) => setConfirmPassword(newValue)}
                        />
                        <p className="u-normal-text">{t('auth.create_account_policy')}</p>
                        <Link
                            label={t('auth.viewThePrivacyPolicy')}
                            underline="always"
                            color={COLORS.purple}
                            type="accent"
                            target="external"
                            href="https://www.fm-world.it/privacy/"
                        />
                        {registerError && (
                            <p className="u-normal-text u-bold-text u-error-text">
                                {t('genral.error')}: {registerError}
                            </p>
                        )}
                        <Button
                            label={t('auth.create_account')}
                            type="submit"
                            onPress={async () => {
                                if (checkDataIsValid) {
                                    await registerUser()
                                }
                            }}
                            disabled={!checkDataIsValid}
                        />
                    </div>
                </div>
            </div>
            <LoadingModal text={t('loadings.registering_account')} isVisible={isLoading} />
            <StaticContentRegisterPage />
        </div>
    )
}

export default RegisterPage
